import * as signalR from "@microsoft/signalr";

export const connection = new signalR.HubConnectionBuilder()
    .withUrl("/api")
    .configureLogging(signalR.LogLevel.Critical)
    .build();

export const startConnection = async () => {
    try {
        if (connection.state === signalR.HubConnectionState.Disconnected) {
            await connection.start().then(() => {
                console.log("SignalR Connected!");
                const connectionId = connection.connectionId;
                console.log(connectionId)
            });

        }
    } catch (err) {
        console.error("SignalR Connection Error: ", err);
        setTimeout(startConnection, 5000); // Retry connection
    }
};

connection.onclose(async () => {
    await startConnection();
});