import React from 'react';
import {Box, Heading, Link, Table, Tbody, Td, Text, Th, Thead, Tr} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MarkdownTheme = {
    p: Text,
    h1: (props) => <Heading as="h1" size="2xl" my={4} {...props} />,
    h2: (props) => <Heading as="h2" size="xl" my={4} {...props} />,
    h3: (props) => <Heading as="h3" size="lg" my={4} {...props} />,
    h4: (props) => <Heading as="h4" size="md" my={4} {...props} />,
    h5: (props) => <Heading as="h5" size="sm" my={4} {...props} />,
    h6: (props) => <Heading as="h6" size="xs" my={4} {...props} />,
    ul: ({children, ...props}) => <ul style={{marginLeft: '24px', listStyleType: 'disc'}} {...props}>{children}</ul>,
    li: ({children, ...props}) => <li {...props}>{children}</li>,
    a: (props) => <Link color="teal.500" isExternal {...props} />,
    table: Table,
    thead: Thead,
    tbody: Tbody,
    tr: Tr,
    th: (props) => <Th {...props} />,
    td: (props) => <Td {...props} />,
    code: ({node, inline, className, children, ...props}) => {
        if (inline) {
            return <code {...props}>{children}</code>;
        }
        const match = /language-(\w+)/.exec(className || '');
        return (
            <SyntaxHighlighter
                style={dark}
                language={match ? match[1] : "html"}
                PreTag="div"
                wrapLines={true}
                customStyle={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        );
    },
};

const MarkdownRenderer = ({ content, ...props }) => {
    return (
        <Box p={"24px"} borderWidth="1px" borderRadius="lg" overflow="hidden" {...props}>
            <ReactMarkdown
                components={MarkdownTheme}
                remarkPlugins={[remarkGfm]}
            >
                {content}
            </ReactMarkdown>
        </Box>
    );
};

export default MarkdownRenderer;