import {Box, Button, Flex, Heading, HStack, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from 'react';
import {useAuth} from "../../auth/AuthContext";
import {Navigate, useNavigate, useParams} from "react-router-dom";

const Dashboard = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const {user, isLoading} = useAuth();
    const [stash, setStash] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetStashDetail?code=GTINonaXAMchVPWkL5rwQ_mZndINfEjXBStCQSmb5CZiAzFu8byAaQ%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
            } catch (error) {
                setError(error.message);
            }
        };

        if (user && user.userId) {
            if (id) {
                fetchStashDetail();
            }
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user, navigate]);

    const handleDelete = async () => {
        try {
            const response = await fetch(`https://stash.azurewebsites.net/api/DeleteStash?code=ytOgBx3A5gXpFk70qJGIu3kx0PaiLqcxpZPXA4buryoIAzFu0gBRYw%3D%3D`, {
                method: 'DELETE',
                body: JSON.stringify({stashId: id, userId: user.userId})
            });
            if (!response.ok) throw new Error('Failed to delete stash');
            setStash(null)
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Flex direction="column" h="100vh">
            <Flex flex="1">
                <Box flex="1" p={8}>
                    {stash && (
                        <Box>
                            <Heading as="h1" mb={4} color="gray.900">{stash.name}</Heading>
                            {/*<Text mb={4} color="gray.700">ID: {stash.id}</Text>*/}
                            <Button colorScheme="red" onClick={handleDelete} mb={4}>Delete Stash</Button>
                            {error && <Text color="red.500">{error}</Text>}
                        </Box>
                    )}
                    {!id && (
                        <Box flex="1" p={8}>
                            {isLoading && <div>Loading...</div>}
                            {!isLoading && !user && <Navigate to="/login"/>}
                            {!isLoading && user && (
                                <Text fontSize="2xl" fontWeight="bold">
                                    Hello, {user.userDetails}
                                </Text>
                            )}
                            <HStack spacing={4} mt={8}>
                                <Box bg="gray.50" w="full" h="100px"/>
                                <Box bg="gray.50" w="full" h="100px"/>
                            </HStack>
                            <HStack spacing={4} mt={4}>
                                <Box bg="gray.50" w="full" h="100px"/>
                                <Box bg="gray.50" w="full" h="100px"/>
                            </HStack>
                            <HStack spacing={4} mt={4}>
                                <Box bg="gray.50" w="full" h="100px"/>
                                <Box bg="gray.50" w="full" h="100px"/>
                            </HStack>
                        </Box>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};

export default Dashboard;