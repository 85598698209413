import React, {useState} from 'react';
import {Box, Button, Heading, HStack, Text, Textarea, VStack} from '@chakra-ui/react';
// import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import MarkdownRenderer from "../../../../component/MarkdownRenderer";
import {useAuth} from "../../../../auth/AuthContext";
import {connection, startConnection} from "../../../../signalr/SignalRService";
import {debounce} from "lodash";

function Search({onBack, stash}) {
    const [llmPrompt, setLlmPrompt] = useState('');
    const [isLLMLoading, setIsLLMLoading] = useState(false);
    const {user} = useAuth();
    const [searchJob, setSearchJob] = useState()

    const handleLlmPromptChange = (event) => {
        setLlmPrompt(event.target.value);
    };

    const performSearch = async () => {
        if (!llmPrompt) {
            alert("Please enter a prompt.");
            return;
        }
        setIsLLMLoading(true);
        try {
            const response = await fetch('https://stash.azurewebsites.net/api/CreateSearchJob?code=uJtyyzJ0su24pW38jr6kOyuREj2HDxg3SHmRrZ0ULoPOAzFuIW9Ctw%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({userId: user.userId, stashId: stash.id, prompt: llmPrompt})
            });
            if (!response.ok) {
                throw new Error('Failed to chat with data');
            }

            const data = await response.json();
            const jobId = data.jobId

            await setupSignalRConnection(user, stash, jobId);
            await fetchSearchJob(user, stash, jobId)
        } catch (error) {
            console.error('Failed to process LLM prompt', error);
        } finally {
            setIsLLMLoading(false);
        }
    };

    const fetchSearchJob = async (user, stash, jobId) => {
        if (user && stash && jobId) {
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetSearchJob?code=xzSfi2YbXJhxX7BVKv9I0-N5pbwKD9czRStDVM3ckFiMAzFuOJtu0A%3D%3D&stashId=${stash.id}&jobId=${jobId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch uploadFiles');
                const job = await response.json();
                console.log(job)
                setSearchJob(job)
            } catch (error) {
                console.error("Couldn't fetch search job: ", error)
            }
        } else {
            console.error("Missing user, stash or jobId")
        }
    };

    const setupSignalRConnection = async (user, stash, jobId) => {
        console.log(stash)
        try {
            // Join the group
            try {
                await fetch(`https://stash.azurewebsites.net/api/AddToGroup?code=WC7DI1qoY_2nXCah0iZIuLy8I94QNqIeS9Ay4qvyA_ZGAzFu-SMbdg%3D%3D&userId=${user.userId}&groupName=${jobId}`, {
                    method: 'GET'
                })
                    .then(response => response.json())
                    .then(data => console.log(data))
                    .catch(error => console.error('Error adding to group:', error));
            } catch (error) {
                console.error('Error adding to group:', error)
            }

            // Start the connection
            await startConnection();

            const handleProgressUpdate = debounce(async (message, groupId) => {
                await fetchSearchJob(user, stash, jobId)
                console.log(`New Message from group ${groupId}: `, message);
            }, 100); // Adjust debounce time as needed

            // Setup event listener for new messages
            connection.on("newMessage", handleProgressUpdate);
        } catch (error) {
            console.error('Error setting up SignalR connection:', error);
        }
    };

    const embedCodeSample = `
    <!-- Embed code for Stash Search -->
    <script src="https://cdn.stashapp.com/stashLoader.js"></script>
    <script>
      loadStashSearch('123e4567-e89b-12d3-a456-426614174000', 'your_api_key_here')
    </script>
    `;

    return (
        <HStack alignItems={"start"} spacing={"24px"}>
            <Box borderWidth="1px" borderRadius="lg" p={4} width={"25%"}>
                <VStack alignItems={"start"} spacing={2}>
                    <Button onClick={onBack}>Back</Button>
                    <Heading as="h2" size="md">Configurations</Heading>
                    <Box>
                        <Text>Widget UI</Text>
                        <Text>Search Behavior</Text>
                        <Text>API Response</Text>
                        <Text>Embed Code</Text>
                    </Box>
                </VStack>
            </Box>
            <VStack spacing={4} align="stretch" width={"50%"}>
                <Heading as="h1" size="xl">Preview</Heading>
                <Text>Search your Stash</Text>
                <Textarea height={"300px"} placeholder="Search or ask..." value={llmPrompt}
                          onChange={handleLlmPromptChange} size="md"/>
                <Button mt={2} colorScheme="blue" onClick={performSearch} isLoading={isLLMLoading}>Run</Button>
                { searchJob && (
                    <>
                        <Text>ID: {searchJob.rowKey}</Text>
                        <Text>Status: {searchJob.status}</Text>
                        <Text>Query: {searchJob.originalQuery}</Text>
                        <Text>Clarifications: {searchJob.questions}</Text>
                        <Text>Objective: {searchJob.researchObjective}</Text>
                        <Text>Answer: {searchJob.answer}</Text>
                    </>
                )}
                <Heading as="h2" size="md">Embed</Heading>
                <MarkdownRenderer content={embedCodeSample}/>
            </VStack>
        </HStack>
    );
}

export default Search;