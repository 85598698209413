import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAuth} from "../../auth/AuthContext";
import {Box, Button, HStack, IconButton, List, ListItem, Spinner, Text, useTheme, VStack} from '@chakra-ui/react';
import {CloseIcon, DeleteIcon} from '@chakra-ui/icons';
import {connection, startConnection} from '../../signalr/SignalRService'
import {v4 as uuidv4} from 'uuid';
import {debounce} from 'lodash';
import {BlockBlobClient} from '@azure/storage-blob';

const Sources = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [stash, setStash] = useState(null);
    const [files, setFiles] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [error, setError] = useState('');
    const [isDragActive, setIsDragActive] = useState(false);
    const fileInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetStashDetail?code=GTINonaXAMchVPWkL5rwQ_mZndINfEjXBStCQSmb5CZiAzFu8byAaQ%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
                console.log(stash)
                await setupSignalRConnection(user, stash);
            } catch (error) {
                setError(error.message);
            }
        };
        if (user && user.userId) {
            fetchStashDetail();
        } else {
            navigate('/login');
        }

        const handleDrag = (event) => {
            if (!isUploading) {
                event.preventDefault();
                setIsDragActive(true);
            }
        };
        const handleDragLeave = (event) => {
            event.preventDefault();
            setIsDragActive(false);
        };
        const handleDrop = (event) => {
            event.preventDefault();
            setIsDragActive(false);
            const files = event.dataTransfer.files;
            onDrop([...files]);
        };

        window.addEventListener('dragover', handleDrag);
        window.addEventListener('dragenter', handleDrag);
        window.addEventListener('dragleave', handleDragLeave);
        window.addEventListener('drop', handleDrop);

        return () => {
            window.removeEventListener('dragover', handleDrag);
            window.removeEventListener('dragenter', handleDrag);
            window.removeEventListener('dragleave', handleDragLeave);
            window.removeEventListener('drop', handleDrop);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user, navigate]);

    useEffect(() => {
        fetchSources(user, stash);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stash, user]);

    const setupSignalRConnection = async (user, stash) => {
        console.log(stash)
        try {
            // Join the group
            try {
                await fetch(`https://stash.azurewebsites.net/api/AddToGroup?code=WC7DI1qoY_2nXCah0iZIuLy8I94QNqIeS9Ay4qvyA_ZGAzFu-SMbdg%3D%3D&userId=${user.userId}&groupName=${id}`, {
                    method: 'GET'
                })
                    .then(response => response.json())
                    .then(data => console.log(data))
                    .catch(error => console.error('Error adding to group:', error));
            } catch (error) {
                setError(error.message);
            }

            // Start the connection
            await startConnection();

            const handleProgressUpdate = debounce(async (message, groupId) => {
                console.log(stash)
                await fetchSources(user, stash)
                console.log(`New Message from group ${groupId}: `, message);
            }, 100); // Adjust debounce time as needed

            // Setup event listener for new messages
            connection.on("newMessage", handleProgressUpdate);
        } catch (error) {
            console.error('Error setting up SignalR connection:', error);
        }
    };

    const fetchSources = async (user, stash) => {
        console.log(user)
        console.log(stash)
        if (user && stash) {
            setIsLoading(true);
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetSources?code=5xmPD0j-u8vAw092Blu-TKpOMAgtfpZhZGsCIci7Hzp9AzFuZ8jbug%3D%3D&stashId=${stash.id}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch uploadFiles');
                const files = await response.json();
                setFiles(files);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        }
    };

    const onDrop = (acceptedFiles) => {
        const allowedTypes = ['application/pdf', 'text/plain'];
        const allowedExtensions = ['.pdf', '.txt'];

        const filteredFiles = acceptedFiles.filter(
            (file) =>
                allowedTypes.includes(file.type) ||
                allowedExtensions.some((ext) =>
                    file.name.toLowerCase().endsWith(ext)
                )
        );

        if (filteredFiles.length !== acceptedFiles.length) {
            setError('Only PDF and TXT files are allowed.');
        }

        const filesWithStatus = filteredFiles.map(file => ({
            id: uuidv4(),
            file: file,
            progress: 0,
            status: 'uploading'
        }));

        setUploadFiles(prevFiles => [...prevFiles, ...filesWithStatus]);
    };

    const removeFile = (index) => {
        setUploadFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    const handleUpload = async () => {
        setError('')
        setIsUploading(true);
        // const formData = new FormData();
        // uploadFiles.forEach((fileObj, index) => {
        //     formData.append(`file_${index}`, fileObj.file);
        //     formData.append(`fileId_${index}`, fileObj.id);
        //     formData.append('dbid', stash.qdrantId);
        // });
        try {
            for (const fileObj of uploadFiles) {
                const filename = fileObj.file.name;

                // Fetch SAS token from your API
                const sasResponse = await fetch(`https://stash.azurewebsites.net/api/GetUploadToken?code=enLngHYRzrFTqkMmTBTVFEtNxWFbQk9mNcICxrZ1dIDiAzFu1qGl-Q%3D%3D&stashId=${id}&filename=${filename}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json'}
                });
                if (!sasResponse.ok) throw new Error('Failed to fetch SAS token');
                const {uploadUrl} = await sasResponse.json();

                // Create the BlockBlobClient
                const blockBlobClient = new BlockBlobClient(uploadUrl);

                // Upload the file with the specified content type
                await blockBlobClient.uploadData(fileObj.file, {
                    blobHTTPHeaders: {
                        blobContentType: fileObj.file.type
                    }
                });
            }

            // const response = await fetch('https://stash.azurewebsites.net/api/UploadFiles?code=7SEP6mhmVwLHN6kdn8cGVIHGnVzSSJ8n6AzuhctNEOJBAzFul_GI3A%3D%3D', {
            //     method: 'POST',
            //     body: formData,
            //     headers: {'x-ms-client-principal': "asdf"}
            // });
            // if (!response.ok) throw new Error('Failed to upload uploadFiles');
            setUploadFiles([]);
            fetchSources(user, stash);
        } catch (error) {
            setUploadFiles(prevFiles => prevFiles.map(fileObj => ({...fileObj, status: 'complete'})));
            fetchSources(user, stash);
        } finally {
            setIsUploading(false);
        }
    };

    const openFileDialog = () => {
        if (!isUploading) {
            fileInputRef.current.click();
        }
    };

    const handleFiles = (event) => {
        const files = event.target.files;
        onDrop([...files]);
    };

    const deleteFile = async (fileId, dbId) => {
        try {
            const response = await fetch(`https://stash.azurewebsites.net/api/DeleteFile?code=_oC1DSzWC8TLXhKmBh_XMk3U6RHwfZ_2NVdZzyk-n2-4AzFu_z7RkQ%3D%3D`, {
                method: 'DELETE',
                body: JSON.stringify({fileId: fileId, dbId: dbId, stashId: id})
            });
            if (!response.ok) throw new Error('Failed to delete the file');
            setFiles(prevFiles => prevFiles.filter(file => file.id !== fileId));
        } catch (error) {
            setError('Failed to delete file: ' + error.message);
        }
    };

    return (
        <Box alignSelf={"start"} p={5} w={"100%"}>
            {!stash && (
                <Text>Loading...</Text>
            )}
            {stash && (
                <div>
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        h={"400px"}
                        p={5}
                        border={isDragActive ? '2px dashed green' : '2px dashed gray'}
                        onClick={openFileDialog}
                        borderRadius="md"
                        boxShadow="md"
                        _hover={isDragActive ? {
                            background: "green.100",
                        } : undefined}
                        bg={theme.colors.gray[50]}
                    >
                        <Text>Drag 'n' drop some files here, or click to select files</Text>
                        {error && (
                            <Text color="red.500">{error}</Text>
                        )}
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            multiple
                            onChange={handleFiles}
                            accept=".pdf,.txt"
                        />
                    </Box>
                    <List spacing={3} mt={4}>
                        {uploadFiles.map((fileObj, index) => (
                            <ListItem key={index} d="flex" alignItems="center" justifyContent="space-between" p={2}
                                      borderRadius="md" boxShadow="sm" bg={theme.colors.gray[100]}>
                                <Text>{fileObj.file.name}</Text>
                                {isUploading ? (
                                    <HStack>
                                        <Spinner size="sm"/>
                                        <Text>Uploading...</Text>
                                    </HStack>
                                ) : (
                                    <IconButton icon={<CloseIcon/>} onClick={() => removeFile(index)}
                                                aria-label="Remove file"
                                                isDisabled={isUploading}/>
                                )}
                            </ListItem>
                        ))}
                    </List>
                    <Button colorScheme="blue" onClick={handleUpload} mt={4}
                            isDisabled={isUploading || uploadFiles.length === 0}>
                        Upload Files
                    </Button>
                    <Box mt={6}>
                        <Text fontSize="lg" fontWeight="bold">Sources</Text>
                        {error && (
                            <Text color={"red.500"}>{error}</Text>
                        )}
                        {/*{isLoading && (*/}
                        {/*    <Text color={"gray.500"}>Loading Files...</Text>*/}
                        {/*)}*/}
                        {!isLoading && files.length === 0 && (
                            <Text>No files found. Get started by adding sources.</Text>
                        )}
                        <List spacing={3} mt={4}>
                            {files.map((file, index) => {
                                if (file.status === "preparing") {
                                    return (
                                        <ListItem
                                            key={index}
                                            d="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            p={2}
                                            borderRadius="md"
                                            boxShadow="sm"
                                            bg={theme.colors.gray[100]}
                                        >
                                            <Text>{file.name}</Text>
                                            <HStack>
                                                <Spinner size="sm"/>
                                                <Text>
                                                    Preparing...
                                                </Text>
                                            </HStack>
                                        </ListItem>
                                    );
                                } else if (file.status === "processing") {
                                    return (
                                        <ListItem
                                            key={index}
                                            d="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            p={2}
                                            borderRadius="md"
                                            boxShadow="sm"
                                            bg={theme.colors.gray[100]}
                                        >
                                            <Text>{file.name}</Text>
                                            <HStack>
                                                <Spinner size="sm"/>
                                                <Text>
                                                    Processing ({file.progress}%)...
                                                </Text>
                                            </HStack>
                                        </ListItem>
                                    );
                                } else if (file.status === "completed") {
                                    return (
                                        <Box
                                            key={index}
                                            p={4}
                                            borderRadius="md"
                                            boxShadow="sm"
                                            bg={theme.colors.gray[50]}
                                            mt={4}
                                        >
                                            <VStack align="start">
                                                <Text fontWeight="bold">{file.name}</Text>
                                                <HStack>
                                                    <Text>{file.pages} Pages</Text>
                                                    <IconButton
                                                        icon={<DeleteIcon/>}
                                                        onClick={() => deleteFile(file.id, stash.qdrantId)}
                                                        aria-label="Delete file"
                                                        colorScheme="red"
                                                    />
                                                </HStack>
                                            </VStack>
                                        </Box>
                                    );
                                }
                                return null;
                            })}
                        </List>
                    </Box>
                </div>
            )}
        </Box>
    );
};

export default Sources;