import React from 'react';
import { HStack } from '@chakra-ui/react';
import Sidebar from './Sidebar';

const Layout = ({ selectedKey, children }) => {
    return (
        <HStack align="start" h="100vh">
            <Sidebar selectedKey={selectedKey} />
            {children}
        </HStack>
    );
};

export default Layout;