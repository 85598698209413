import React, { useEffect } from 'react';
import { Button, Container } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';

function Login() {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/dashboard', { replace: true });
        }
    }, [user, navigate]);

    return (
        <Container centerContent>
            <a href="/.auth/login/google">
                <Button colorScheme="blue">Sign In With Google</Button>
            </a>
        </Container>
    );
}

export default Login;