import React, {useState} from 'react';
import {Box, Button, Heading, HStack, Text, Textarea, useDisclosure, VStack} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import MarkdownRenderer from "../../../../component/MarkdownRenderer";

function Chat({onBack, stash}) {
    const [llmPrompt, setLlmPrompt] = useState('');
    const [llmOutput, setLlmOutput] = useState('');
    const [isLLMLoading, setIsLLMLoading] = useState(false);
    const [sources, setSources] = useState([]);

    const handleLlmPromptChange = (event) => {
        setLlmPrompt(event.target.value);
    };

    const chatWithData = async () => {
        if (!llmPrompt) {
            alert("Please enter a prompt.");
            return;
        }
        setIsLLMLoading(true);
        try {
            const response = await fetch('https://stash.azurewebsites.net/api/ChatWithData?code=ExH7ggq810FS3cZp8sEqwNMVn3uB3KWjXifMmZM7YiOVAzFuKHk0VQ%3D%3D', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({stashId: stash.id, prompt: llmPrompt})
            });
            if (!response.ok) {
                throw new Error('Failed to chat with data');
            }
            const data = await response.json();
            setLlmOutput(data.result);
            setSources(data.sources.sort((a, b) => a.pageIndex - b.pageIndex)); // Sort sources by pageIndex
        } catch (error) {
            console.error('Failed to process LLM prompt', error);
            setLlmOutput(`Error: ${error.message}`);
        } finally {
            setIsLLMLoading(false);
        }
    };

    const embedCodeSample = `
    <!-- Embed code for Stash Chat -->
    <script src="https://cdn.stashapp.com/stashLoader.js"></script>
    <script>
      loadStashChat('123e4567-e89b-12d3-a456-426614174000', 'your_api_key_here')
    </script>
    `;

    return (
        <HStack alignItems={"start"} spacing={"24px"}>
            <Box borderWidth="1px" borderRadius="lg" p={4} width={"25%"}>
                <VStack alignItems={"start"} spacing={2}>
                    <Button onClick={onBack}>Back</Button>
                    <Heading as="h2" size="md">Configurations</Heading>
                    <Box>
                        <Text>Widget UI</Text>
                        <Text>Search Behavior</Text>
                        <Text>API Response</Text>
                        <Text>Embed Code</Text>
                    </Box>
                </VStack>
            </Box>
            <VStack spacing={4} align="stretch" width={"50%"}>
                <Heading as="h1" size="xl">Preview</Heading>
                <Text>Chat with your Stash</Text>
                <Textarea height={"300px"} placeholder="Enter LLM prompt here..." value={llmPrompt}
                          onChange={handleLlmPromptChange} size="md"/>
                <Button mt={2} colorScheme="blue" onClick={chatWithData} isLoading={isLLMLoading}>Run</Button>
                <Box mt={2} p={4} border="1px solid" borderColor="gray.200">
                    <Text>Output:</Text>
                    <MarkdownRenderer content={llmOutput ? llmOutput : ""}/>
                    {sources.map((source, index) => (
                        <SourceCard key={index} source={source}/>
                    ))}
                </Box>
                <Heading as="h2" size="md">Embed</Heading>
                <MarkdownRenderer content={embedCodeSample}/>
            </VStack>
        </HStack>
    );
}

function SourceCard({source}) {
    const {isOpen, onToggle} = useDisclosure();
    const previewContent = source.result.slice(0, 100) + '...';

    return (
        <Box mt={4} borderWidth="1px" borderRadius="lg" p={4} cursor="pointer" onClick={onToggle}>
            <HStack justifyContent="space-between">
                <Text fontWeight="bold">Page Index: {source.pageIndex}</Text>
                <Button size="sm" onClick={(e) => e.stopPropagation()}>
                    {isOpen ? <ChevronUpIcon/> : <ChevronDownIcon/>}
                </Button>
            </HStack>
            <Text>{isOpen ? <MarkdownRenderer content={source.result}/> : previewContent}</Text>
        </Box>
    );
}

export default Chat;