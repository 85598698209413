import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('/.auth/me')
            .then(res => res.json())
            .then(data => {
                if (data.clientPrincipal) {
                    setUser({
                        ...data.clientPrincipal,
                        userId: data.clientPrincipal.userId,
                        userDetails: data.clientPrincipal.userDetails
                    });
                } else {
                    setUser(null);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setUser(null);
                setIsLoading(false);
            });
    }, []);

    return (
        <AuthContext.Provider value={{ user, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
}