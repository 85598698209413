import React, {useRef, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    useOutsideClick,
} from '@chakra-ui/react';
import {StashIconDark, StashIconLight} from "./StashIcon";

const CreateStashModal = ({isOpen, onClose, onCreate}) => {
    const [iconColor, setIconColor] = useState('gray.100');
    const [avatarTextColor, setAvatarTextColor] = useState('gray.500');
    const [stashIcon, setStashIcon] = useState(<StashIconDark/>)
    const [name, setName] = useState('');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef(null);
    const initialFocusRef = useRef(null);

    useOutsideClick({
        ref: popoverRef,
        handler: () => setIsPopoverOpen(false),
    });

    const handleColorChange = (color) => {
        setIconColor(color);
        setAvatarTextColor(isLightColor(color) ? "black" : "white")
        setStashIcon(isLightColor(iconColor) ? <StashIconDark/> : <StashIconLight/>)
        setIsPopoverOpen(false);
    };

    const openPopover = () => setIsPopoverOpen(true);
    const closePopover = () => setIsPopoverOpen(false);

    const colors = [
        "#8077F1", "#6A85FF", "#40A6E5", "#3FB1B2", "#64C6A2", "#E3EF00", "#EFAD93",
        "#DC646A", "#F17EAD", "#BBA399", "#595D66", "#E3E4E5", "#020C0E", "#FDFDFD"
    ];

    function isLightColor(hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance > 128;
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered initialFocusRef={initialFocusRef}>
                <ModalOverlay/>
                <ModalContent bg="gray.50" w="525px" boxShadow="xl" borderRadius="16px">
                    <ModalHeader bg="white" borderTopRadius="16px" textStyle="h4">Create Stash</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody bg="white" boxShadow="xs" borderBottomRadius="16px" position="relative" zIndex="1">
                        <Text mb={4} textStyle="body">
                            A "Stash" is a place where you can combine different types of information, which is then
                            organized to easily create tools like chatbots or search engines for your website.
                        </Text>
                        <FormControl mb={4}>
                            <FormLabel textStyle="h6">Icon & Name</FormLabel>
                            <Box display="flex" alignItems="center">
                                <Popover isOpen={isPopoverOpen} onClose={closePopover} closeOnBlur={true}
                                         ref={popoverRef}>
                                    <PopoverTrigger>
                                        <Avatar
                                            w="44px"
                                            h="44px"
                                            bg={iconColor ? iconColor : "gray.100"}
                                            name={name}
                                            color={avatarTextColor}
                                            cursor="pointer"
                                            icon={stashIcon}
                                            onClick={openPopover}
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow/>
                                        <PopoverBody>
                                            <Box display="flex" flexWrap="wrap">
                                                {colors.map((color) => (
                                                    <Box key={color} position="relative" m={1} cursor="pointer"
                                                         onClick={() => handleColorChange(color)}>
                                                        <Box bg={color} w={6} h={6} borderRadius="full"
                                                             position="relative" display="flex" alignItems="center"
                                                             justifyContent="center">
                                                            {color === iconColor && (
                                                                <Box position="absolute" top="-2px" left="-2px"
                                                                     right="-2px" bottom="-2px"
                                                                     border="1px solid #020C0E" borderRadius="full"/>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                                <Input
                                    ml={2}
                                    placeholder="e.g. Search, Marketing, Engineering"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    border="2px"
                                    borderColor="gray.300"
                                    _focus={{borderColor: 'black'}}
                                    textStyle="label"
                                    ref={initialFocusRef}
                                />
                            </Box>
                        </FormControl>
                    </ModalBody>
                    <ModalFooter bg="gray.50" borderBottomRadius="16px" zIndex="0">
                        <Button
                            colorScheme="gray"
                            bg={name ? "gray.700" : "none"}
                            color={name ? "white" : "gray.300"}
                            borderRadius="38px"
                            onClick={() => onCreate(name, iconColor)}
                            isDisabled={!name}
                        >
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CreateStashModal;