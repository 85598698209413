import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    colors: {
        gray: {
            50: "#FDFDFD",
            100: "#E3E4E5",
            200: "#C7CACB",
            300: "#AAAFB0",
            400: "#8E9596",
            500: "#727A7C",
            600: "#566062",
            700: "#394547",
            800: "#1D2B2D",
            900: "#020C0E",
        },
        neon: {
            50: "#FCFDE6",
            100: "#F8FAC1",
            200: "#F3F896",
            300: "#EDF56A",
            400: "#E7F142",
            500: "#E3EF00",
            600: "#D5DC00",
            700: "#C4C500",
            800: "#B3AE00",
            900: "#988600",
        },
        green: {
            50: "#E7EAEA",
            100: "#C2CBCC",
            200: "#9AA9AB",
            300: "#72888B",
            400: "#567075",
            500: "#395A5F",
            600: "#304D51",
            700: "#233A3E",
            800: "#17292C",
            900: "#051618",
        },
        peach: {
            50: "#F6E9E7",
            100: "#F5CBDD",
            200: "#EFAD93",
            300: "#EC8C66",
            400: "#EB7642",
            500: "#E95F1E",
            600: "#DF5A1B",
            700: "#D25316",
            800: "#C44C13",
            900: "#AC3F0A",
        },
    },
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "20px",
        xl: "24px",
        "2xl": "32px",
        "3xl": "40px",
        "4xl": "48px",
    },
    fontWeights: {
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
    },
    textStyles: {
        h1: {
            fontSize: "48px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        h2: {
            fontSize: "40px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        h3: {
            fontSize: "32px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        h4: {
            fontSize: "24px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        h5: {
            fontSize: "20px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        h6: {
            fontSize: "16px",
            fontWeight: "semibold",
            lineHeight: "1.2",
        },
        body: {
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "1.5",
        },
        "body-semi-bold": {
            fontSize: "14px",
            fontWeight: "medium",
            lineHeight: "1.5",
        },
        label: {
            fontSize: "12px",
            fontWeight: "normal",
            lineHeight: "1.5",
        },
        "label-semi-bold": {
            fontSize: "12px",
            fontWeight: "medium",
            lineHeight: "1.5",
        },
    },
});

export default theme;