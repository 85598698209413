import {Avatar, Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {Navigate, useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthContext";
import React from "react";

const Header = () => {
    const {user, isLoading} = useAuth();
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        navigate(`/logout`);
    };

    return (
        <Box bg="white" px={4} py={2}>
            <Flex h={16} alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <img src="/stash-logo-horizontal.svg" alt="Logo" style={{height: '24px', marginRight: '8px'}}/>
                </Box>
                <Flex alignItems="center">
                    {/*<IconButton size="lg" variant="ghost" aria-label="Notifications" icon={<BellIcon/>} mr={4}/>*/}
                    <Menu>
                        <MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
                            {isLoading && <div>Loading...</div>}
                            {!isLoading && !user && <Navigate to="/login"/>}
                            {!isLoading && user && (
                                <Avatar size="sm" name={user.userDetails} bg="gray.900" color="white"/>
                            )}
                            <ChevronDownIcon ml={2}/>
                        </MenuButton>
                        <MenuList>
                            {/*<MenuItem>Profile</MenuItem>*/}
                            {/*<MenuItem>Settings</MenuItem>*/}
                            <MenuItem onClick={() => handleLogoutClick()}>Logout</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Header;