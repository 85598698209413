import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Button, Divider,
    Flex, HStack,
    List,
    ListItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import CreateStashModal from "./CreateStashModal";
import {FaChartBar, FaChevronDown, FaDatabase, FaFile, FaHome, FaPlus, FaRocket, FaWrench} from "react-icons/fa";
import {StashIconDark, StashIconLight} from "./StashIcon";
import {useAuth} from "../auth/AuthContext";

const Sidebar = ({ selectedKey }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {user} = useAuth();
    const [stashes, setStashes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [stash, setStash] = useState(null);

    const pages = [
        { id: 'dashboard', label: 'Dashboard', icon: <FaHome />, enabled: true },
        { id: 'sources', label: 'Sources', icon: <FaDatabase />, enabled: true },
        { id: 'data', label: 'Data', icon: <FaFile />, enabled: false },
        { id: 'configurations', label: 'Configurations', icon: <FaWrench />, enabled: false },
        { id: 'deploy', label: 'Deploy', icon: <FaRocket />, enabled: true },
        { id: 'insights', label: 'Insights', icon: <FaChartBar />, enabled: false },
    ];

    const handleStashClick = (id) => {
        navigate(`/stashes/${id}`);
    };

    useEffect(() => {
        fetchStashes();

        const fetchStashDetail = async () => {
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetStashDetail?code=GTINonaXAMchVPWkL5rwQ_mZndINfEjXBStCQSmb5CZiAzFu8byAaQ%3D%3D&id=${id}&userId=${user.userId}`, {
                    method: 'GET'
                });
                if (!response.ok) throw new Error('Failed to fetch stash details');
                const stash = await response.json();
                setStash(stash);
            } catch (error) {
                setError(error.message);
            }
        };

        if (user && user.userId) {
            if (id) {
                fetchStashDetail();
            }
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line
    }, [id, user]);

    useEffect(() => {
        setStash(null)
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (!id && stashes && stashes.length > 0) {
            //We can't get a stash detail, default to first stash if we have one.
            handleStashClick(stashes[0].id)
        }
        // eslint-disable-next-line
    }, [stashes]);

    const fetchStashes = async () => {
        if (user && user.userId) {
            setIsLoading(true);
            try {
                const response = await fetch(`https://stash.azurewebsites.net/api/GetStashes?code=4BEXApcI-FiLYDr7m2gTUJnaylIl0XUx9ZQKfAqdy_EmAzFuVkykqg%3D%3D&userId=${user.userId}`, {
                    method: "GET"
                });
                if (!response.ok) throw new Error("Failed to fetch stashes");
                const data = await response.json();
                setStashes(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleOnCreate = (name, iconColor) => {
        onClose()
        addStash(name, iconColor)
    }

    const addStash = async (name, iconColor) => {
        try {
            const response = await fetch("https://stash.azurewebsites.net/api/AddStash?code=e_2UTP-_FJ7GK8rMTx7NMghXVyqQ14w9hex54JLCreVEAzFugRaP2g%3D%3D", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({name, iconColor, userId: user.userId}),
            });
            if (!response.ok) throw new Error("Failed to create stash");
            const data = await response.json();
            handleStashClick(data.stash.rowKey)
        } catch (error) {
            setError(error.message);
        }
    };

    function isLightColor(hex) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        return luminance > 128;
    }

    const handleNavigation = (page) => {
        if (page === 'dashboard') {
            if (stash) {
                navigate(`/stashes/${stash.id}`);
            } else {
                navigate('/dashboard');
            }
        } else {
            navigate(`/stashes/${stash.id}/${page}`);
        }
    };

    return (
        <HStack alignSelf={'start'} h="100vh">
            <Box w="250px" h="100vh" bg="gray.50" color="gray.800" display="flex" flexDirection="column">
                <CreateStashModal
                    isOpen={isOpen}
                    onClose={onClose}
                    onCreate={(name, iconColor) => handleOnCreate(name, iconColor)}
                />
                <VStack align="stretch" p="5" flex="1">
                    <Menu>
                        {stash && (
                            <MenuButton
                                as={Button}
                                rightIcon={<FaChevronDown />}
                                variant="ghost"
                                justifyContent="start"
                            >
                                <Flex align="center">
                                    <Avatar
                                        w="44px"
                                        h="44px"
                                        bg={stash.iconColor ? stash.iconColor : 'gray.100'}
                                        name={stash.name}
                                        color={isLightColor(stash.iconColor) ? 'black' : 'white'}
                                        cursor="pointer"
                                        icon={
                                            isLightColor(stash.iconColor) ? <StashIconDark /> : <StashIconLight />
                                        }
                                    />
                                    <Text ml="3" flex="1" noOfLines={1} isTruncated>
                                        {stash.name}
                                    </Text>
                                </Flex>
                            </MenuButton>
                        )}
                        {isLoading && <MenuItem>Loading...</MenuItem>}
                        {!isLoading && !stash && (
                            <MenuItem icon={<FaPlus/>} onClick={onOpen}> Create New </MenuItem>
                        )}
                        <MenuList>
                            <MenuItem icon={<FaPlus/>} onClick={onOpen}> Create New </MenuItem>
                            {isLoading && <MenuItem>Loading...</MenuItem>}
                            {error && <Text color="red.500">{error}</Text>}
                            {stashes.map((stash) => (
                                <MenuItem key={stash.id} onClick={() => handleStashClick(stash.id)}>
                                    <Flex align="center">
                                        <Avatar w="44px" h="44px" bg={stash.iconColor ? stash.iconColor : "gray.100"}
                                                name={stash.name} color={isLightColor(stash.iconColor) ? "black" : "white"}
                                                cursor="pointer" icon={isLightColor(stash.iconColor) ? <StashIconDark/> :
                                            <StashIconLight/>}/>
                                        <Text ml="3" flex="1" noOfLines={1} isTruncated>
                                            {stash.name}
                                        </Text>
                                    </Flex>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    {stash && (
                        <List spacing={3}>
                            {pages.map((page) => (
                                <NavItem
                                    key={page.id}
                                    id={page.id}
                                    label={page.label}
                                    icon={page.icon}
                                    enabled={page.enabled}
                                    color={stash.iconColor}
                                    isSelected={selectedKey === page.id}
                                    handleNavigation={handleNavigation}
                                />
                            ))}
                        </List>
                    )}
                </VStack>
            </Box>
            <Divider orientation="vertical" />
        </HStack>
    );
};

export default Sidebar

const NavItem = ({id, label, icon, enabled, color, isSelected, handleNavigation}) => {
    return (
        <ListItem>
            <Button
                w={"100%"}
                onClick={() => handleNavigation(id)}
                isDisabled={!enabled}
                justifyContent={"start"}
                p="2"
                bg={isSelected ? "gray.100" : "gray.50"}
                _hover={!isSelected && {bg: "gray.100"}}
            >
                {icon}
                <Text ml="3">{label}</Text>
            </Button>
        </ListItem>
    )
}